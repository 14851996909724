<template>
	<div ref="mobileRef" class="nav-mobile-modal">
		<div class="wrapper">
			<ul>
				<li v-for="item in content.navigation_items" :key="item.name">
					<a :href="item.url">{{item.name}}</a>
					<hr />
				</li>
				<li>
					<a href="/abonnemang">Teckna abonnemang</a>
					<hr />
				</li>
				<li>
					<a class="bokaBtn" href="https://www.matchi.se">Boka</a>
					<hr />
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
import axios from 'axios'
import { baseUrl } from '@/assets/apiLink.js'
export default {
	props: {},
	data: function () {
		return {
			baseApiUrl: baseUrl,
			content: {},
		}
	},
	created() {
		window.addEventListener('resize', this.handleResize)
	},
	destroyed() {
		window.removeEventListener('resize', this.handleResize)
	},
	mounted() {
		axios.get(this.baseApiUrl + '/umbraco/api/settings/getsettings').then((response) => {
			this.content = response.data
		})
	},
	methods: {
		handleResize: function () {
			if (window.innerWidth > 1023) {
				this.$refs.mobileRef.classList.remove('visible')
			}
		},
	},
}
</script>
<style scoped lang="scss">
.nav-mobile-modal {
	@include default-padding;
	padding-top: 30px;
	visibility: hidden;
	opacity: 0;
	position: fixed;
	top: 86px;
	left: 0;
	height: calc(100vh - 86px);
	width: 100vw;
	z-index: 999;
	background-color: $color-grey-dark;

	&.visible {
		visibility: visible;
		opacity: 1;
	}
	.wrapper {
		display: flex;
		flex-direction: column;
		ul {
			height: 100%;
		}
		li {
			margin-bottom: 20px;
			a {
				@include paragraph;

				text-transform: uppercase;
				letter-spacing: 0.7px;
				margin-right: 30px;
				font-weight: 600;
				color: #fff;
			}
			hr {
				margin-top: 12px;
				width: calc(100vw - 125px);
				background-color: #c1c3c518;
				opacity: 0.1;
				height: 0.1px;

				@include max-width(small) {
					width: calc(100vw - 45px);
				}
			}
		}
		li:last-child {
			height: 45px;
			width: 100%;
			display: flex;
			justify-content: center;
			a {
				display: flex;
				position: relative;
				top: 20px;
				width: 200px;
				margin-right: 0;
				align-items: center;
				justify-content: center;
				@include paragraph-small;
				background-color: $color-green;
				padding: 15px 40px;
				border-radius: 50px;
			}

			hr {
				display: none;
			}
		}
	}
}

.pinkRibbonTheme {
	.nav-mobile-modal {
		.bokaBtn {
			background-color: $color-darkpink !important;
			border: $color-darkpink !important;
		}
	}
}
</style>
